const CssIcon = ({ className }) => {
  return (
    <svg
      className={className}
      aria-label="CSS3"
      role="img"
      viewBox="0 0 512 512"
    >
      <path fill="#264de4" d="M72 460L30 0h451l-41 460-184 52" />
      <path fill="#2965f1" d="M256 37V472l149-41 35-394" />
      <path
        fill="#ebebeb"
        d="m114 94h142v56H119m5 58h132v57H129m3 28h56l4 45 64 17v59L139 382"
      />
      <path
        fill="#ffffff"
        d="m256 208v57h69l-7 73-62 17v59l115-32 26-288H256v56h80l-5.5 58Z"
      />
    </svg>
  );
};

export default CssIcon;
